.page-header-spacing {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 40;
  background-color: white;
  width: 100%;
}

.page-header-font {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}

.page-global-spacing {
  padding: 2rem 3rem;
}
